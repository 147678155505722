import axios from 'axios'

const baseURL = process.env.VUE_APP_API_URL || ''
const stripePublicKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY || ''

class ApiService {
  async get (url, config = {}) {
    const response = await axios.get(`${baseURL}/${url}`, config)

    if (response.error) {
      return Promise.reject(new Error(`Error GET ${url} : ${JSON.stringify(response)}`))
    }

    return response
  }

  async post (url, data = {}, config = {}) {
    config.headers || (config.headers = {
      'Content-Type': 'application/json'
    })
    const response = await axios.post(`${baseURL}/${url}`, data, config)

    if (response.error) {
      return Promise.reject(new Error(`Error POST ${url} : ${JSON.stringify(response)}`))
    }

    return response
  }

  async createCheckoutSession(name, email) {
    var headers = {
      'Content-Type': 'application/json'
    }
    var data = { name, email }
    const response = await this.post('create-checkout-session', data, { headers })
    return response.data
  }

  async createCharge(name, email, stripeTokenId) {
    var headers = {
      'Content-Type': 'application/json'
    }
    var data = { name, email, stripeTokenId }
    const response = await this.post('create-charge', data, { headers })
    return response.data
  }

  async publishLicenseKey(stripeSessionId) {
    var headers = {
      'Content-Type': 'application/json'
    }
    var data = { stripe_sessionId: stripeSessionId }
    const response = await this.post('publish-license-key', data, { headers })
    return response.data
  }
}

export default new ApiService()
