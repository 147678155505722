<template>
  <nav
    class="navbar navbar-expand-lg navbar-light bg-light bg-transparent"
    id="gtco-main-nav"
  >
    <div class="container">
      <a class="navbar-brand">Image<span class="bold">Kitchen</span></a>
      <form class="form-inline my-2 my-lg-0">
        <a
          :href="platforms[platform].url"
          class="btn btn-outline-dark my-2 my-sm-0 mr-3 text-uppercase"
          >download - {{platforms[platform].name}}</a
        >
        <!-- <a href="#" class="btn btn-info my-2 my-sm-0 text-uppercase">buy now</a> -->
      </form>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      platforms: {
        windows: {
          name: "PC",
          url: "https://imagekitchen.s3.us-east-2.amazonaws.com/image-kitchen+Setup+0.0.2.exe"
        },
        mac: {
          name: "Mac",
          url: "https://imagekitchen.s3.us-east-2.amazonaws.com/image-kitchen-0.0.2.dmg"
        }
      }
    };
  },
  computed: {
    platform() {
      if (!navigator) return 'unknown';
      if (navigator.appVersion.includes("Win")) {
        return "windows";
      } else if (navigator.appVersion.includes("Mac")){
        return "mac";
      } else if (navigator.appVersion.includes("Linux")){
        return "linux";
      } else {
        return "unknown";
      }
    }
  }
}
</script>