<template>
  <div
    class="container-fluid gtco-checkout gtco-feature"
    style="margin-top: 20px"
    id="checkout"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-6" id="contact">
          <h4>Get a License</h4>
          <input
            type="text"
            class="form-control"
            v-model="name"
            placeholder="Full Name"
          />
          <input
            type="email"
            class="form-control"
            v-model="email"
            placeholder="Email Address"
          />
          <a
            @click="checkout"
            class="submit-button"
            style="color: white; cursor: pointer"
          >
            {{ isChecking ? "Checking..." : "Buy Now" }}
            <i class="fa fa-angle-right" aria-hidden="true"></i>
          </a>
            <pulse-loader
              :loading="isChecking"
              color="#aaaaaa"
              width="1px"
              height="1px"
            ></pulse-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../services/api'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import {loadStripe} from '@stripe/stripe-js';

export default {
  components: {
    PulseLoader
  },  
  props: {
  },
  data() {
    return {
      name: '',
      email: '',
      isChecking: false
    };
  },  
  methods: {
    async checkout() {
      if (this.email == '') {
        return;
      }
      this.isChecking = true
      try {
        const { sessionId } = await ApiService.createCheckoutSession(this.name, this.email)
        const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
        const { error } = await stripe.redirectToCheckout({ sessionId });
      } catch (e) {
        
      }
      this.isChecking = false
    },
    showSuccessToast() {
      this.$toast.success("The checkout is successed. Please check your email.", {
        position: "top-right",
        timeout: 5000
      });
    },
    showFailedToast() {
      this.$toast.error("The checkout is failed.", {
        position: "top-right",
        timeout: 5000
      });
    },
    async publishLicenseKey(stripeSessionId) {
      await ApiService.publishLicenseKey(stripeSessionId)
      this.showSuccessToast()
    }
  },
  mounted() {
    const params = this.$route.query
    if (params.checkout != undefined) {
      if (params.checkout == "true") {
        this.publishLicenseKey(params.session_id)
      } else {
        this.showFailedToast()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
