<template>
  <div>
    <div class="container-fluid gtco-banner-area">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h1>Complete Workflow <span>Solution</span>.</h1>
            <p>
              ImageKitchen is a complete solution for image resizing and
              renaming large image collections.
            </p>
            <p>Compress, resize and rename images in bulk.</p>
          </div>
          <div class="col-md-6">
            <div class="card">
              <img
                class="card-img-top img-fluid"
                src="images/banner-img.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Checkout />
    <div class="container-fluid gtco-feature" id="services">
      <div class="container">
        <div class="row">
          <div class="col-md-7">
            <div class="cover">
              <div class="card">
                <svg
                  class="back-bg"
                  width="100%"
                  viewBox="0 0 900 700"
                  style="position: absolute; z-index: -1"
                >
                  <defs>
                    <linearGradient
                      id="PSgrad_01"
                      x1="64.279%"
                      x2="0%"
                      y1="76.604%"
                      y2="0%"
                    >
                      <stop
                        offset="0%"
                        stop-color="rgb(1,230,248)"
                        stop-opacity="1"
                      />
                      <stop
                        offset="100%"
                        stop-color="rgb(29,62,222)"
                        stop-opacity="1"
                      />
                    </linearGradient>
                  </defs>
                  <path
                    fill-rule="evenodd"
                    opacity="0.102"
                    fill="url(#PSgrad_01)"
                    d="M616.656,2.494 L89.351,98.948 C19.867,111.658 -16.508,176.639 7.408,240.130 L122.755,546.348 C141.761,596.806 203.597,623.407 259.843,609.597 L697.535,502.126 C748.221,489.680 783.967,441.432 777.751,392.742 L739.837,95.775 C732.096,35.145 677.715,-8.675 616.656,2.494 Z"
                  />
                </svg>
                <!-- *************-->

                <svg width="100%" viewBox="0 0 700 500">
                  <clipPath id="clip-path">
                    <path
                      d="M89.479,0.180 L512.635,25.932 C568.395,29.326 603.115,76.927 590.357,129.078 L528.827,380.603 C518.688,422.048 472.661,448.814 427.190,443.300 L73.350,400.391 C32.374,395.422 -0.267,360.907 -0.002,322.064 L1.609,85.154 C1.938,36.786 40.481,-2.801 89.479,0.180 Z"
                    ></path>
                  </clipPath>
                  <!-- xlink:href for modern browsers, src for IE8- -->
                  <image
                    xlink:href="images/learn-img.jpg"
                    width="80%"
                    height="465"
                    class="svg__image"
                  ></image>
                </svg>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <h2>Image Compression Without Compromise</h2>
            <p>
              Compress your images without compromising on the quality. Increase
              or decrease image’s maximum width and height to fit exact size
              specifications, while preserving detail and sharpness.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid gtco-features" id="about">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <h2>Folder Management</h2>
            <p>
              Keep nested folder structure intact to maintain library structure
              or flatten folders to compile images neatly together under one
              folder to simplify upload or image sharing.
            </p>
          </div>
          <div class="col-lg-8">
            <svg id="bg-services" width="100%" viewBox="0 0 1000 800">
              <defs>
                <linearGradient
                  id="PSgrad_02"
                  x1="64.279%"
                  x2="0%"
                  y1="76.604%"
                  y2="0%"
                >
                  <stop
                    offset="0%"
                    stop-color="rgb(1,230,248)"
                    stop-opacity="1"
                  />
                  <stop
                    offset="100%"
                    stop-color="rgb(29,62,222)"
                    stop-opacity="1"
                  />
                </linearGradient>
              </defs>
              <path
                fill-rule="evenodd"
                opacity="0.102"
                fill="url(#PSgrad_02)"
                d="M801.878,3.146 L116.381,128.537 C26.052,145.060 -21.235,229.535 9.856,312.073 L159.806,710.157 C184.515,775.753 264.901,810.334 338.020,792.380 L907.021,652.668 C972.912,636.489 1019.383,573.766 1011.301,510.470 L962.013,124.412 C951.950,45.594 881.254,-11.373 801.878,3.146 Z"
              />
            </svg>
            <div class="row">
              <div class="col">
                <div class="card text-center">
                  <div class="oval">
                    <img
                      class="card-img-top"
                      src="images/web-design.png"
                      alt=""
                    />
                  </div>
                  <div class="card-body">
                    <h3 class="card-title">Organize</h3>
                    <p class="card-text">
                      You no longer need multiple apps to rename, compress and organize large image libraries.
                    </p>
                  </div>
                </div>
                <div class="card text-center">
                  <div class="oval">
                    <img
                      class="card-img-top"
                      src="images/marketing.png"
                      alt=""
                    />
                  </div>
                  <div class="card-body">
                    <h3 class="card-title">Improve Performance</h3>
                    <p class="card-text">
                      Smaller image files allows for faster load times and improved performance.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card text-center">
                  <div class="oval">
                    <img class="card-img-top" src="images/seo.png" alt="" />
                  </div>
                  <div class="card-body">
                    <h3 class="card-title">Save Cost</h3>
                    <p class="card-text">
                      Save on bandwidth and server storage costs.
                    </p>
                  </div>
                </div>
                <div class="card text-center">
                  <div class="oval">
                    <img
                      class="card-img-top"
                      src="images/graphics-design.png"
                      alt=""
                    />
                  </div>
                  <div class="card-body">
                    <h3 class="card-title">Fast & Simple</h3>
                    <p class="card-text">
                      Simple UI takes care of all your image management needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkout from './Checkout.vue'
export default {
  components: {
    Checkout
  }
}
</script>
