<template>
  <footer class="container-fluid" id="gtco-footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-6" id="contact">
          <!-- <h4>Contact Us</h4>
          <input type="text" class="form-control" placeholder="Full Name" />
          <input
            type="email"
            class="form-control"
            placeholder="Email Address"
          />
          <textarea class="form-control" placeholder="Message"></textarea>
          <a href="#" class="submit-button"
            >Send<i class="fa fa-angle-right" aria-hidden="true"></i
          ></a> -->
        </div>
        <div class="col-lg-6">
          <div class="row">
            <div class="col-6">
              <h4>Contact Us</h4>
              <a href="#" class="footer-link">help@ImageKitchen.app</a>
            </div>
            <div class="col-6">
              <h4>ImageKitchen</h4>
              <ul class="nav flex-column company-nav">
                <li class="nav-item">
                  <a class="nav-link" href="#checkout">Buy now</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" :href="platforms[platform].url">Download - {{ platforms[platform].name }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      platforms: {
        windows: {
          name: "PC",
          url: "https://imagekitchen.s3.us-east-2.amazonaws.com/image-kitchen+Setup+0.0.2.exe"
        },
        mac: {
          name: "Mac",
          url: "https://imagekitchen.s3.us-east-2.amazonaws.com/image-kitchen-0.0.2.dmg"
        }
      }
    };
  },
  computed: {
    platform() {
      if (!navigator) return 'unknown';
      if (navigator.appVersion.includes("Win")) {
        return "windows";
      } else if (navigator.appVersion.includes("Mac")){
        return "mac";
      } else if (navigator.appVersion.includes("Linux")){
        return "linux";
      } else {
        return "unknown";
      }
    }
  }
}
</script>