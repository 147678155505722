import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
// import CheckoutSuccess from '../components/CheckoutSuccess.vue'
// import CheckoutFailed from '../components/CheckoutFailed.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  // {
  //   path: '/checkout-success',
  //   name: 'checkout-success',
  //   component: CheckoutSuccess
  // },
  // {
  //   path: '/checkout-failed',
  //   name: 'checkout-failed',
  //   component: CheckoutFailed
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
